var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"custom-debugx",staticStyle:{"position":"relative","top":"13px"},attrs:{"size":20,"color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{on:{"submit":[function($event){return _vm.updateList()},function($event){$event.preventDefault();}]},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" List Settings ")]),_c('v-card-text',[_c('div',[_c('v-text-field',{ref:"itemTextRef",attrs:{"label":"List Name","rules":_vm.lettersNumbersAndSpaces},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editListName),callback:function ($$v) {_vm.editListName=$$v},expression:"editListName"}}),(_vm.dupeListLink!='')?_c('div',{staticClass:"mt-1"},[_c('h3',[_vm._v(" List name already used "),_c('a',{attrs:{"href":_vm.dupeListLink}},[_vm._v(_vm._s(_vm.dupeListLink))])])]):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_vm._v(" Tags "),_c('TagSelector',{ref:"tagSelector",attrs:{"listId":_vm.listId}})],1),_c('div',{staticClass:"mb-0 pb-0",staticStyle:{"display":"flex","align-content":"start"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-6"},[_vm._v(" Select Icon "),_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.listIconOptions,"item-value":"list_icon_id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('ListIcon',{attrs:{"listIconSlug":item.list_icon_slug,"size":"large"}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('ListIcon',{attrs:{"listIconSlug":item.list_icon_slug,"size":"large"}}),_c('v-spacer'),(item.is_system == 0)?_c('v-icon',{attrs:{"size":"30","color":"dark green"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteListIcon(item.list_icon_id)}}},[_vm._v(" mdi-alpha-x ")]):_vm._e()]}}]),model:{value:(_vm.selectedListIconId),callback:function ($$v) {_vm.selectedListIconId=$$v},expression:"selectedListIconId"}})],1),_c('div',{staticClass:"col col-4 offset-2"},[_vm._v(" Upload Icon "),_c('picture-input',{ref:"iconUpload",attrs:{"accept":"image/jpeg, image/png","button-class":"btn","height":"50","margin":"0","size":"1","width":"50","hideChangeButton":true,"plain":true,"custom-strings":{ upload: '', drag: 'X' }},on:{"change":_vm.onChange}})],1)]),_c('div',[_c('v-text-field',{attrs:{"label":"Display Sequence"},model:{value:(_vm.editDisplaySequence),callback:function ($$v) {_vm.editDisplaySequence=$$v},expression:"editDisplaySequence"}})],1),_c('div',[_c('v-select',{attrs:{"label":"Public Access","items":['None', 'Visible', 'Edit', 'Add Only']},model:{value:(_vm.editPublicAccess),callback:function ($$v) {_vm.editPublicAccess=$$v},expression:"editPublicAccess"}})],1),_c('div',[_c('v-select',{attrs:{"label":"Friend Access","items":['None', 'Visible', 'Edit', 'Add Only']},model:{value:(_vm.editFriendAccess),callback:function ($$v) {_vm.editFriendAccess=$$v},expression:"editFriendAccess"}})],1),_c('div',[_c('v-select',{attrs:{"label":"List Content Format","items":_vm.listFormats,"item-text":"label","item-value":"value"},model:{value:(_vm.editFormatId),callback:function ($$v) {_vm.editFormatId=$$v},expression:"editFormatId"}})],1)]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isValid,"color":"green white--text"}},[_vm._v(" SAVE ")]),_c('v-btn',{attrs:{"type":"button","color":"grey white--text"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" CANCEL ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }