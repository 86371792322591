<template>
    <v-row>
        <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" :size="20" v-on="on" class="custom-debugx" style="position:relative; top: 13px;" color="green">
                    mdi-cog
                </v-icon>
                <!-- <CustomIcon v-on="on" v-bind="attrs" toolTip="Settings" glyph="mdi-cog" style="position:relative; top: 13px;" />  -->

            </template>
            <v-form @submit="updateList()" v-model="isValid" v-on:submit.prevent>
            <v-card>
                <v-card-title class="headline"> List Settings </v-card-title>
                <v-card-text>
                    <div>
                        <v-text-field v-model="editListName" label="List Name" ref="itemTextRef" :rules="lettersNumbersAndSpaces" @focus="$event.target.select()" />
                        <div v-if="dupeListLink!=''" class="mt-1">
                            <h3> List name already used <a :href="dupeListLink">{{dupeListLink}}</a> </h3>
                        </div>
                    </div>
                    <div class="mb-4">
                        Tags
                        <TagSelector ref="tagSelector" :listId="listId" />
                    </div>
                    <div class="mb-0 pb-0" style="display: flex; align-content: start;">
                    </div>
                    <div class="row">
                        <div class="col col-6">
                            Select Icon
                            <v-select v-model="selectedListIconId" :items="listIconOptions" item-value="list_icon_id" class="pt-0">
                                <template v-slot:selection="{item}">
                                    <ListIcon :listIconSlug="item.list_icon_slug" size="large" />
                                </template>
                                <template v-slot:item="{ item }">
                                    <ListIcon :listIconSlug="item.list_icon_slug" size="large" />
                                    <v-spacer />
                                    <v-icon v-if="item.is_system == 0" @click.stop="deleteListIcon(item.list_icon_id)" size="30" color="dark green"> mdi-alpha-x </v-icon>
                                </template>
                            </v-select>
                        </div>
                        <div class="col col-4 offset-2">
                            Upload Icon
                            <picture-input 
                                accept="image/jpeg, image/png" 
                                button-class="btn"
                                height="50" margin="0" size="1" width="50" 
                                :hideChangeButton="true"
                                :plain="true"
                                ref="iconUpload"
                                :custom-strings="{ upload: '', drag: 'X' }"
                                @change="onChange">
                            </picture-input>
                        </div>
                    </div>
                    <div>
                        <v-text-field v-model="editDisplaySequence" label="Display Sequence" />
                    </div>
                    <div>
                        <v-select v-model="editPublicAccess" label="Public Access" :items="['None', 'Visible', 'Edit', 'Add Only']" ></v-select>
                    </div>
                    <div>
                        <v-select v-model="editFriendAccess" label="Friend Access" :items="['None', 'Visible', 'Edit', 'Add Only']" ></v-select>
                    </div>
                    <div>
                        <v-select v-model="editFormatId" label="List Content Format" :items="listFormats" item-text="label" item-value="value" ></v-select>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn type="submit" :disabled="!isValid" color="green white--text" >
                        SAVE
                    </v-btn>
                    <v-btn type="button" @click="cancel()" color="grey white--text" >
                        CANCEL
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>
<script>
    import api from '/src/utils/api';
    import PictureInput from 'vue-picture-input';

    export default {
        components: {
            // CustomIcon: () => import("/src/components/CustomIcon.vue"),
            ListIcon: () => import("/src/components/ListIcon"),
            PictureInput,
            TagSelector: () => import("/src/components/TagSelector.vue"),
        },
        data() {
            return {
                channelName: "",
                dialog: false,
                dupeListLink: "",
                editDisplaySequence: 1,
                editListName: "",
                editFormatId: 1,
                editFriendAccess: "",
                editPublicAccess: "",
                isValid:false,
                lettersNumbersAndSpaces: [
                    str => !!str || 'Name is required',
                    str => /^[A-Za-z\d\s]*$/.test(str) || 'Only letters, numbers and spaces are allowed.',
                ],
                listFormats:[
                    { label: 'Plain Text', value: 1 },
                    { label: 'Media', value: 2 },
                ],
                listIconOptions: [],
                selectedListIconId: null,
                originalListName: "",
            }
        },
        async mounted() {
            // console.log("MODAL:: listId", this.listId);
            // TODO: Maybe just pass in the list object instead of making api call.
            api.getCall(`get-list?listId=${this.listId}`).then(lists => {
                const l = lists[0];
                this.channelName = l.channel_name;
                this.editDisplaySequence = l.display_sequence;
                this.editListName = l.list_name;
                this.editFormatId = l.list_format_id;
                this.editPublicAccess = l.public_access;
                this.editFriendAccess = l.friend_access;
                this.selectedListIconId = l.list_icon_id;
                this.originalListName = l.list_name;
            });

            this.iconOptionRefresh();
        },
        methods: {
            async updateList() {
                let dupeListName = false;

                // If list name was modified, then we will check if a list exists already with that new name.
                if (this.originalListName != this.editListName) {
                    let dupeCheck = await api.getCall(`get-list?channel=${this.channelName}&list=${this.editListName}`);
                    dupeListName = dupeCheck[0] ? true : false;
                }

                // If list name already exists, provide a link to the user to view this list.
                if (dupeListName) {
                    this.dupeListLink = window.location.origin + "/" + this.channelName + "/" + this.editListName;
                }
                else {
                    let body = {
                        listId: this.listId,
                        displaySequence: this.editDisplaySequence,
                        name: this.editListName,
                        formatId: this.editFormatId,
                        publicAccess: this.editPublicAccess,
                        friendAccess: this.editFriendAccess,
                        listIconId: this.selectedListIconId,
                    };

                    api.postAuthRequired('update-list-settings',body).then((res) => {
                        this.dialog = false;
                        this.handleSuccess(this.editListName);
                    });
                }

                this.$refs.tagSelector.updateListTags();
            },
            iconOptionRefresh(forceId) {
                api.getCall(`get-list-icons`).then(listIconOptions => {
                    this.listIconOptions = listIconOptions;
                    this.selectedListIconId = forceId || this.selectedListIconId;
                });
            },
            deleteListIcon(listIconId) {
                console.log("delete icon::listIconId", listIconId);
                api.postAuthRequired('delete-list-icon', {listIconId}).then(() => {
                    this.iconOptionRefresh();
                });

                // event.stopPropagation();
            },
            cancel() {
                this.dialog = false;
            },
            onChange() {
                // After icon is uploaded, it is now part of the icon options for the user.
                // We refresh the options so the new icon appears and select it. However its not chosen until user clicks Save.
                api.uploadIcon( this.$refs.iconUpload.file ).then(response=>{
                    this.iconOptionRefresh(response[0].list_icon_id);
                })
                .catch(err=>{
                    console.error("ERRORR:",err);
                });
            },
        },
        props: ['listId','handleSuccess'],
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .row {}
</style>
